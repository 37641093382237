.join .container[data-v-474f97f0] {
  margin: 1.2rem auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.join .container .listbox[data-v-474f97f0] {
  margin: 0 auto;
  font-size: 0.16rem;
}
.join .container .listbox img[data-v-474f97f0] {
  width: 60%;
}
.join .container .listbox .listbox-text[data-v-474f97f0] {
  font-size: 0.36rem;
  color: #ccc;
}
.join .container .listBox1[data-v-474f97f0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.join .container[data-v-474f97f0] .showarea strong {
  font-weight: bold !important;
}
.join .container[data-v-474f97f0] .showarea h1,
.join .container[data-v-474f97f0] .showarea h2,
.join .container[data-v-474f97f0] .showarea h3,
.join .container[data-v-474f97f0] .showarea h4,
.join .container[data-v-474f97f0] .showarea h5,
.join .container[data-v-474f97f0] .showarea h6 {
  font-weight: bold !important;
}
.join .container[data-v-474f97f0] .showarea table {
  width: calc(100% - 0.16rem) !important;
}